:root {
  --background-color-light: white;
  --color-primary: #2AFFEE;
  --color-primary-rgb: 42, 255, 238;
  --color-primary-shade: #18ADAD;
  --color-danger: #FE2A50;
  --color-danger-light: #FFCCD4;
  --color-danger-rgb: 254, 42, 80;
  --color-warning: #EC8E49;

  body[color-theme="light"] {
    --background-color: var(--background-color-light);
    --background-color-rgb: 255, 255 ,255;
    --background-color-darker: #D9D9D9;
    --background-color-grey-disabled: #DADADA;
    --background-color-invert: var(--background-color-dark);
    --background-color-invert-rgb: 37, 40, 45;
    --background-color-grey: white;
    --background-color-grey-tint: #EFEFF0;
    --background-color-grey-shade: var(--background-color-grey-tint);
    --text-color: black;
    --text-color-invert: white;
    --text-color-rgb: 0, 0, 0;
    --text-color-disabled-rgb: 18, 19, 23;
    --color-danger: #FA1039;
    --color-danger-rgb: 250, 16, 57;
    --color-danger-02: #FA103933;
    --color-contrast: #121317;
    --color-contrast-06: #12131799;

    .content-block {
      background-color: var(--background-color);

      &:has(.content-block) {
        background-color: var(--background-color-grey-tint);
      }

      & > .content-block {
        background-color: white;
      }
    }

    ion-toast {
      --background: #35383E;
    }

    app-calendar {
      --cancel-button-color: transparent;
    }

    app-checkbox {
      --border-color: rgb(18 19 23 / 20%);
    }

    ion-icon {
      &[src='assets/icons/calendar.svg'] {
        filter: invert(1);
      }
    }
  }
}