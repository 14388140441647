/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Swiper JS styles */
@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/scss/pagination";
@import "swiper/scss/autoplay";
@import "swiper/scss/virtual";

// фикс на iOS, чтобы контент не вылезал за шторку
ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
}

ion-header {
  padding: 0 5px;

  &.header-md {
    box-shadow: none;
  }

  ion-toolbar {
    --background: transparent;

    color: white;
    
    &:first-of-type {
      padding: 0;
    }
  }
}

.tab-bar.ios {
  padding-bottom: 0;
}

// с помощью этого фиксим белый экран на iOS <= 13.3
.ion-page {
  width: 100%;
  height: 100%;
  background: var(--color-dark);
  padding: 5px;
}

// fix работы ссылок для iOS <= 15.0
a {
  pointer-events: initial;
}

h1 {
  margin: 0 0 14px;
}

// в ионике 7 этот атрибут не работает, поэтому тут восстанавливаем его работу
[hidden] {
  display: none !important;
}

[none-visibility="true"] {
  visibility: hidden;
}

// отключаем иконку с "часами" для инпутов с типом "time"
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

// стили для скроллбара, который должен отображаться принудительно
.force-scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
  }
}

ion-toast {
  --color: var(--text-color-invert);
  --border-radius: 14px;

  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  transform: translateY(-57px);

  &.popup {
    &::part(container) {
      flex-direction: column-reverse;

      .toast-content {
        display: none;
      }
    }

    &::part(message) {
      text-align: center;
      padding-bottom: 24px;
      font-weight: 500;
      white-space: pre-line;
    }

    &::part(cancel) {
      font-size: 1.5rem;
      opacity: 0.6;
    }
  }

  &.announcement-info {
    --color: #5aacd8;
    --button-color: #5aacd8;
  }

  &.announcement-issue {
    --color: #ffab00;
    --button-color: #ffab00;
  }
}

// включаем возможность скролла страницы, если палец попадает на input
ion-input {
  touch-action: pan-y !important;
}

.selection-input {
  .native-input.sc-ion-input-md,
  .native-input.sc-ion-input-ios {
    cursor: pointer;
  }
}

// включаем возможность переноса текста внутри ion-select-popover
.sc-ion-select-popover-md,
.sc-ion-select-popover-ios {
  white-space: normal !important;
}

// включаем возможность переноса текста в ion-alert
.alert-tappable.sc-ion-alert-md,
.alert-tappable.sc-ion-alert-ios {
  height: initial;
  contain: initial;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

ion-modal {
  --background: transparent;

  margin-bottom: 57px;

  &.full-view {
    width: 100%;
    height: 100%;
    margin: 0;

    .ion-page {
      padding: 0 5px;
    }
  }

  &.max-content {
    --width: max-content;
    --height: max-content;
  }

  &.auto-height {
    --height: auto;
    --border-radius: 20px;

    margin: 0;

    &::part(handle) {
      width: 60px;
      height: 5px;
      margin: 10px auto;
      background-color: rgba(var(--background-color-invert-rgb), 0.1);
    }

    .ion-page {
      height: auto;
    }

    .content-block {
      padding: 30px 0;
      background-color: var(--ion-color-custom-grey);
      height: max-content;
      border-radius: 0;
  
      &.success, &.error {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 15px;
        border-radius: 16px;

        .success-check, .error-check {
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          padding: 16px 32px;
          background: var(--color-primary);
          border-radius: 88px;

          &.error-check {
            background: var(--color-danger);
          }
        }
        
        .text {
          display: flex;
          flex-direction: column;
          gap: 10px;

          ion-text {
            font-weight: 500;
            font-size: 1.125rem;
          }
        }
      }

      .control-buttons {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 20px;
        padding: 0 15px;
  
        ion-button {
          width: 100%;
        }
      }
    }
  }

  .ion-page {
    padding: 0;
  }

  &:not(.auto-height) {
    &::part(content) {
      top: 0;
      position: absolute;
    }
  
    .content-block {
      height: 100%;
    }
  }

  .items-disabled {
    app-car-card {
      --color: var(--text-color-disabled-rgb);

      app-state-number {
        --color: var(--text-color-disabled-rgb);
      }
    }
  }
}

app-service-management {
  app-car-card {
    --color: var(--text-color-disabled-rgb);

    app-state-number {
      --color: var(--text-color-disabled-rgb);
    }
  }
}

.content-block {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 16px;
  background-color: var(--background-color);
  color: var(--text-color);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ion-icon[name="chevron-forward-outline"] {
      font-size: 1.6rem;
    }
  }

  h1 {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.375rem;
    margin: 0;
    color: var(--text-color);
  }

  &:has(.content-block) {
    padding: 15px 0;
    
    .content-block {
      margin: 0 5px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 5px !important;
  }

  .content-block {
    background-color: white;
  }
}

.step-bar {
  width: auto;
  flex: 1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  padding: 10px 16px;
  background-color: rgba(var(--color-primary-rgb), 0.16);
  font-weight: 700;

  .step {
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
  }

  .all-steps-count {
    margin-left: auto;
    opacity: 0.6;
  }
}

ion-modal.stack-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-alert {
  .alert-wrapper.sc-ion-alert-md {
    border-radius: 8px;
    position: relative;
    background-color: transparent;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      filter: blur(1px);
      background-color: rgb(18 19 23 / 80%);
      z-index: -1;
    }

    .alert-message {
      text-align: center;
      font-weight: 500;
      color: white;
      font-size: 1.25rem;
    }
  
    .alert-button-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;

      button {
        font-weight: 700;
        font-size: 1.25rem;
      }
    }
  }

  .text-grey {
    color: var(--ion-color-grey);
  }

  .text-primary {
    color: var(--color-primary);
    font-weight: bold;
  }
}

@for $i from 1 through 5 {
  .text-ellipsis-#{$i}-row {
    // stylelint-disable-next-line property-no-vendor-prefix
    -moz-box-orient: vertical;
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-box-orient: vertical;
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -moz-box;
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
    -webkit-line-clamp: $i;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

swiper-container {
  width: 100%;
  padding-left: 5px;

  &::part(container) {
    padding-bottom: 30px;
  }
    
  &::part(bullet-active) {
    width: 30px;
    height: 4px;
    background-color: var(--background-color-invert);
    border-radius: 6px;
  }

  &::part(bullet) {
    width: 4px;
    height: 4px;
    background-color: rgba(var(--background-color-invert-rgb), 0.5);
  }
}

.notification-toast {
  cursor: pointer;
}

.types-selector {
  display: flex;
  gap: 5px;
  overflow-x: scroll;
  padding: 0 5px;
  
  .type {
    padding: 10px 12px;
    text-transform: uppercase;
    color: var(--text-color);
    background-color: var(--background-color-grey);
    border-radius: 100px;
    font-weight: 700;
    font-size: 0.875rem;

    &.active {
      color: var(--text-color-invert);
      background-color: var(--background-color-invert);
    }
  }
}

ion-icon, img {
  &.invert-color {
    filter: invert(1);
  }
}

ion-title {
  text-transform: uppercase;
  font-size: 1.625rem;
  padding: 0;
  margin: 0;
}

ion-segment {
  --background: rgb(42 255 238 / 16%);

  border-radius: 8px;

  &-button {
    &.segment-button-checked {
      --color: var(--color-primary);
    }

    --border-radius: 8px;
    --indicator-color: rgb(78 245 245 / 16%);
    --color: rgb(42 255 238 / 60%);

    height: 36px;
    text-transform: uppercase;
    font-weight: 500;
  }
}

ion-content {
  --background: transparent;
}

ion-list {
  &.list-md, &.list-ios {
    background: transparent;
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }
}

ion-searchbar {
  &.sc-ion-searchbar-ios-h {
    --background: white;
    --border-radius: 10px;

    ion-icon {
      position: absolute;
      right: 10px;
      zoom: 1.5;
      z-index: 1;
    }

    .searchbar-input {
      padding-inline-start: 15px !important;
      padding: 18px 15px;
      font-weight: 500;

      &::placeholder {
        text-transform: uppercase;
        opacity: 0.6;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }
  }
}

ion-form {  
  ion-input.sc-ion-input-ios-h,
  ion-input.sc-ion-input-md-h,
  ion-textarea.sc-ion-textarea-ios-h,
  ion-textarea.sc-ion-textarea-md-h {
    --background: white;
    --padding-start: 16px;
    --padding-top: 22px;
    --padding-bottom: 22px;
    --border-radius: 10px;
    --border-color: unset;

    border-radius: 10px;
    border: none;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--color-contrast);

    &:has([slot="end"]>ion-text) {
      &[slot="end"] {
        display: flex;
        width: 100%;
      }

      .label-text-wrapper {
        min-width: 60%;
      }

      .native-input {
        max-width: 0;
      }
    }

    &.hide-label {
      .label-text-wrapper {
        display: none;
      }
    }

    .input-wrapper {
      border: 2px solid transparent;
    }
    
    &.has-focus:not([readonly="true"]), &.highlighted {
      .input-wrapper {
        border-color: var(--color-contrast);
      }
    }

    &.input-label-placement-floating {
      --padding-top: 5px;
      --padding-bottom: 18px;
      
      .native-input, .covered-value {
        margin-top: 5px;
      }
      
      .covered-value {
        min-width: fit-content;
      }

      .native-input {
        min-width: 60%;
      }
    }

    .covered-value + .native-input {
      opacity: 0;
    }

    .label-text-wrapper {
      color: var(--color-contrast-06);
      min-width: 85%;
    }

    ion-icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.6;
      z-index: 10;

      &.full-opacity {
        opacity: 1;
        font-size: 1.5rem;
      }
    }

    &.ion-invalid:not(.has-focus) {
      color: var(--color-danger);

      ion-icon {
        color: var(--text-color);
      }
    }

    &.ion-touched.ion-invalid {
      --border-color: unset;
    }

    .input-bottom {
      padding-inline-start: unset;

      .error-text {
        display: unset;
        text-transform: none;
        font-size: 0.625rem;
        color: var(--color-danger);
        background-color: #FE2A5033;
        border-radius: 20px;
        padding: 4px 8px;
      }
    }
  }

  ion-item {
    --border-radius: 10px;

    ion-label.sc-ion-label-ios-h {
      text-transform: uppercase;
      opacity: 0.6;
      font-weight: 500;
    }
  }
}

ion-popover {
  &.auto-width {
    --width: auto;
  }

  &.highlighted-popover {
    margin-top: 5px;

    &::part(content) {
      border-radius: 13px;
      box-shadow: none;
      border: 2px solid var(--background-color-invert);
    }
  }

  &.reference-popover {
    --backdrop-opacity: 0;
    --width: 100%;
    --offset-x: -4%;
    --offset-y: -10%;
    --box-shadow: none;

    margin: 10px;

    &::part(content) {
      border-radius: 8px;
    }
  }
}

// custom popover for calendar
ion-popover#calendar {
  ion-content {
    .selector-wrapper {
      .items-list {
        .item {
          font-size: 1.0625rem;
          text-transform: capitalize;
  
          &.selected {
            background-color: var(--color-emphasis-02);

            .checkmark {
              display: none;
            }
          }
        }
      }
    }
  }
}

ion-checkbox {
  --checkmark-width: 3px;

  &::part(container) {
    border-color: transparent;
  }

  &.checkbox-checked {
    position: relative;

    &::part(container) {
      background: black;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 50%;
        width: 50%;
        background-color: white;
        border-radius: 100%;
      }
    }
    
    &::part(mark) {
      display: none;
    }
  }
}

ion-toggle {
  --track-background-checked: var(--color-dark);
}

ion-back-button {
  --background: rgb(78 245 245 / 16%);
  --border-radius: 32px;
  --padding-end: 0;
  --padding-start: 0;

  display: flex !important;
  align-items: center;
  color: var(--color-primary);
  width: 50px;
  height: 40px;

  &::part(native) {
    --padding-end: 0;
    --padding-start: 0;
    --padding-bottom: 0;
    --padding-top: 0;

    width: 50px;
    height: 40px;
    min-height: 40px;
  }
}

ion-button {
  --box-shadow: none;
  --background-hover-opacity: 0;
  --background-focused-opacity: 0;
  --background-activated-opacity: 0;

  width: max-content;
  font-weight: 700;
  text-transform: uppercase;

  &::part(native) {
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-bottom: 15px;
    --padding-top: 15px;
    --border-radius: 16px;
  }

  &:has(ion-icon+ion-text) {
    ion-icon {
      margin-right: 10px;
    }
  }

  &:has(ion-icon:only-child) {
    width: 50px;
    height: 40px;

    &::part(native) {
      --border-radius: 32px !important;
      --padding-end: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-top: 0;

      width: 50px;
      height: 40px;
      min-height: auto;
      min-width: auto;
    }
  }

  &:has(.button-content) {
    &::part(native) {
      --border-radius: 10px;
      --padding-end: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-top: 0;
    }

    .button-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 18px 22px;
      text-transform: uppercase;

      ion-icon {
        zoom: 1.8;
      }
    }
  }

  &.primary {
    --background: rgb(78 245 245 / 16%);

    color: var(--color-primary);

    ion-icon {
      width: 25px;
      height: 25px;
      filter: brightness(0) saturate(100%) invert(90%) sepia(70%) saturate(6564%) hue-rotate(129deg) brightness(102%) contrast(100%);
    }
  }

  &.round {
    border-radius: 32px;

    &::part(native) {
      --border-radius: 32px;
    }
  }

  &.half-round {
    border-radius: 16px;
  }

  &.bordered {
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }

  &.back-button {
    --color: var(--color-primary);
    --background: rgba(var(--color-primary-rgb), 0.16);

    font-size: 1.1rem;
  }

  &.height-40 {
    margin: 0;

    --height: 40px;

    height: 40px;

    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;

    font-size: 0.938rem;
  }

  &[fill="outline"] {
   &::part(native) {
    border-radius: 32px;
    border-width: 2px;
   } 
  }

  &.file-upload-button {
    letter-spacing: unset;

    &[fill="outline"] {
      &::part(native) {
        border-radius: 10px;
      }
    }
  }
}

ion-footer {
  padding: 0 10px;
  padding-bottom: 10px;

  ion-button {
    height: 50px;
  }
}

ion-refresher {
  &.refresher-native {
    ion-spinner {
      color: var(--ion-color-primary-shade);      
    }
  
    .arrow-container ion-icon {
      color: var(--ion-color-primary-shade);   
    }
  }
}

/* кастомные иониковские цвета */

:root {
  --ion-color-custom-dark: #2d3542;
  --ion-color-custom-dark-rgb: 45, 53, 66;
  --ion-color-custom-dark-contrast: #fff;
  --ion-color-custom-dark-contrast-rgb: 255, 255, 255;
  --ion-color-custom-dark-shade: #282f3a;
  --ion-color-custom-dark-tint: #424955;
  --ion-color-custom-grey: #EFEFF0;
  --ion-color-custom-grey-rgb: 61, 69, 83;
  --ion-color-custom-grey-contrast: #fff;
  --ion-color-custom-grey-contrast-rgb: 255, 255, 255;
  --ion-color-custom-grey-shade: #363d49;
  --ion-color-custom-grey-tint: #505864;
  --ion-color-custom-green: #2affee;
  --ion-color-custom-green-rgb: 42, 255, 238;
  --ion-color-custom-green-contrast: #000;
  --ion-color-custom-green-contrast-rgb: 0, 0, 0;
  --ion-color-custom-green-shade: #25e0d1;
  --ion-color-custom-green-tint: #3ffff0;
  --ion-color-custom-orange: #f26122;
  --ion-color-custom-orange-rgb: 242, 97, 34;
  --ion-color-custom-orange-contrast: #000;
  --ion-color-custom-orange-contrast-rgb: 0, 0, 0;
  --ion-color-custom-orange-shade: #d5551e;
  --ion-color-custom-orange-tint: #f37138;
}

.ion-color-custom-dark {
  --ion-color-base: var(--ion-color-custom-dark);
  --ion-color-base-rgb: var(--ion-color-custom-dark-rgb);
  --ion-color-contrast: var(--ion-color-custom-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-dark-shade);
  --ion-color-tint: var(--ion-color-custom-dark-tint);
}

.ion-color-custom-grey {
  --ion-color-base: var(--ion-color-custom-grey);
  --ion-color-base-rgb: var(--ion-color-custom-grey-rgb);
  --ion-color-contrast: var(--ion-color-custom-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-grey-shade);
  --ion-color-tint: var(--ion-color-custom-grey-tint);
}

.ion-color-custom-green {
  --ion-color-base: var(--ion-color-custom-green);
  --ion-color-base-rgb: var(--ion-color-custom-green-rgb);
  --ion-color-contrast: var(--ion-color-custom-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-green-shade);
  --ion-color-tint: var(--ion-color-custom-green-tint);
}

.ion-color-custom-orange {
  --ion-color-base: var(--ion-color-custom-orange);
  --ion-color-base-rgb: var(--ion-color-custom-orange-rgb);
  --ion-color-contrast: var(--ion-color-custom-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-orange-shade);
  --ion-color-tint: var(--ion-color-custom-orange-tint);
}
