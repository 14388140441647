$rubik-font-path: "./Rubik" !default;

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src:
    url("#{$rubik-font-path}/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  src:
    url("#{$rubik-font-path}/Rubik-Bold.ttf") format("truetype"),
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src:
    url("#{$rubik-font-path}/Rubik-Medium.ttf") format("truetype"),
}