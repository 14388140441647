$pt-font-path: "./PT-Root-UI" !default;

@font-face {
  font-family: PTRootUI;
  font-style: normal;
  font-weight: 400;
  src:
    url("#{$pt-font-path}/pt-root-ui_regular.woff2") format("woff2");
}

@font-face {
  font-family: PTRootUI;
  font-style: normal;
  font-weight: bold;
  src:
    url("#{$pt-font-path}/pt-root-ui_bold.woff2") format("woff2"),
}

@font-face {
  font-family: PTRootUI;
  font-style: normal;
  font-weight: 500;
  src:
    url("#{$pt-font-path}/pt-root-ui_medium.woff2") format("woff2"),
}