:root {
  --background-color-dark: #25282D;

  body[color-theme="dark"] {
    --color-primary-shade: var(--color-primary);
    --background-color: var(--background-color-dark);
    --background-color-rgb: 37,40,45;
    --background-color-darker: var(--background-color-dark);
    --background-color-invert: var(--background-color-light);
    --background-color-invert-rgb: 255, 255, 255;
    --background-color-grey: #35383E;
    --background-color-grey-tint: var(--background-color-grey-graphite);
    --background-color-grey-shade: var(--background-color-dark);
    --text-color: white;
    --text-color-invert: black;
    --text-color-rgb: 255, 255, 255;
    --text-color-disabled-rgb: 255, 255, 255;
    --color-danger: #FE2A50;
    --color-danger-rgb: 254, 42, 80;
    --color-danger-02: #FE2A5033;
    --color-contrast: #fff;
    --color-contrast-06: #fff9;

    .content-block {
      background-color: var(--background-color);

      & > .content-block {
        background-color: var(--background-color-grey);
      }
    }

    ion-toast {
      --background: #FFF;
    }

    app-calendar {
      --day-color: white;
      --today-color: white;
      --cancel-button-color: white;
      --done-button-color: white;
      --footer-border-top: 1px solid rgb(255 255 255 / 60%);

      background-color: var(--color-dark);
    }

    ion-toggle {
      --track-background: rgb(255 255 255 / 30%);
      --handle-background-checked: white;
      --track-background-checked: rgb(78 245 245 / 16%);
    }

    ion-searchbar.sc-ion-searchbar-ios-h {
      --background: var(--background-color-grey);
      --color: var(--text-color);

      ion-icon {
        filter: invert(1);
      }
    }

    ion-form {  
      ion-input.sc-ion-input-ios-h,
      ion-input.sc-ion-input-md-h,
      ion-textarea.sc-ion-textarea-ios-h,
      ion-textarea.sc-ion-textarea-md-h {
        --background: var(--background-color-grey);
        --color: var(--text-color);
    
        &.has-focus:not([readonly="true"]), &.highlighted {
          border: 2px solid rgba(var(--background-color-invert-rgb), 0.6);
        }
      }
  
    }

    .header {
      .segment-wrapper {
        ion-segment {
          --background: var(--background-color-grey) !important;
    
          ion-segment-button {
            --indicator-color: rgb(78 245 245 / 16%) !important;
          }
        }
      }
    }

    app-checkbox {
      --background-color: #696B70;
      --border-color: rgb(255 255 255 / 20%);

      .checkbox-wrapper{
        .checkbox {
          &.disabled {
            background-color: rgba(var(--color-dark-rgb), 0.3);
            border: 1px solid rgba(var(--background-color-rgb), 0.1);
          }
        }
      }
    }

    ion-icon {
      &.allow-theming-color {
        filter: invert(1);
      }
    }
  }
}